/****
 * Laterpay Custom Javascript Access Method Output handler
 *
 * This will be used for any LaterPay customjavascript access metho.
 *
 * For it to function, the customjavascript access method must return an object in this format:
     return {
        has_access:[bool], // boolean that reports whether the user should be granted access
        data:{
            type:'custom_laterpay', // type to tell the script which output handler to use it must be be custom_laterpay
            name:[string], // name of the custom access method to be displayed to the user
            class_name: [string], // custom class name of element displayed in the list of access methods
            description: [string], // description text to be displayed in the access method
            purchase_text: [string], // text for the purchase button for the access method
            purchase_url: [string] // url to be provided to the user to allow them to gain access
        }
    };
 */
 
window.TNCMS = window.TNCMS || {};
window.TNCMS.Access = TNCMS.Access || [];
window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.access || (__tnt.subscription.access = []);
window.__tnt.subscription.access.output || (__tnt.subscription.access.output = {});

__tnt.subscription.access.output.custom_laterpay = function(method){
    var modalEl = document.getElementById('service-promo-modal'),
        methodsEl = null,
        servicePanelClasses = "promotion-service subscription-service panel panel-default",
        refererUrl = '',
        offersEl = document.getElementById('access-offers-modal'),
        template = null,
        wrapper = null;

    if(modalEl){ methodsEl = modalEl.querySelector('.access-methods-list') }
    if(offersEl){ 
        methodsEl = offersEl.querySelector('.access-methods-list');
        wrapper = document.getElementById('access-methods');
    }else{
        template = methodsEl.querySelector('template.template-promo-service');
        wrapper = methodsEl.querySelector('.output');
    }

    if(wrapper){ refererUrl = wrapper.getAttribute('data-referer') }

    if(method.response){
        var lpIcon = document.createElementNS('http://www.w3.org/2000/svg','svg'),
            lpIconG = document.createElementNS('http://www.w3.org/2000/svg','g'),
            lpIconPath = document.createElementNS('http://www.w3.org/2000/svg','path'),
            lpButton = document.createElement('a'),
            lpButtonText = document.createElement('span'),
            customClassName = "";

        if(method.response.class_name){ customClassName = " " + method.response.class_name; }

        lpIcon.id = "logo-laterpay";
        lpIcon.style.height = "18px";
        lpIcon.style.position = "relative";
        lpIcon.style.top = "2px";
        lpIcon.style.padding = "0 8px 0 0";
        lpIcon.setAttribute('viewBox','0 0 500 500');
        lpIconG.setAttribute("fill-rule","nonzero");
        lpIconPath.setAttribute("style","fill:#ffffff;fill-opacity:1");
        lpIconPath.setAttribute("d","M317,479.4c-12,0-23.3-7.6-27.4-19.6c-5.2-15.1,2.9-31.6,18-36.8c71.5-24.6,119.5-91.9,119.5-167.5\
c0-97.6-79.4-177.1-177.1-177.1S72.9,157.9,72.9,255.6c0,75.6,48,143,119.5,167.5c15.1,5.2,23.2,21.7,18,36.8s-21.7,23.2-36.8,18\
C78.7,445.3,15,355.9,15,255.6c0-129.6,105.4-235,235-235s235,105.4,235,235c0,100.4-63.7,189.7-158.6,222.3\
C323.3,478.9,320.1,479.4,317,479.4z");

        lpIconG.appendChild(lpIconPath);
        lpIcon.appendChild(lpIconG);

        lpButtonText.innerText = method.response.purchase_text; 
        
        lpButton.appendChild(lpIcon);
        lpButton.appendChild(lpButtonText);

        /*
         * Elements to modify within the template element before it is added to the DOM and displayed to the user.
         */
        var elements = [
            {
                'element' : '.subscription-service.panel',
                'attr' : [
                    {
                        'attr' : 'class',
                        'value' : servicePanelClasses + customClassName
                    }
                ]
            },{
                'element' : '.method-header',
                'html' : method.response.name
            },{
                'element' : '.service-note',
                'html': method.response.note
            },{
                'element' : '.method-description',
                'html' : method.response.description
            },{
                'element' : 'a.method-link',
                'html' : lpButton.innerHTML,
                'attr': [
                    {
                        'attr' : 'data-name',
                        'value' : method.response.name
                    },{
                        'attr' : 'href',
                        'value' : method.response.purchase_url + '&referer_url=' + refererUrl
                    }
                ]
            }
        ];
        
        if(offersEl){
            elements.push({
                'element':'.offer-details',
                'html': method.response.description
            });
        }
    
        /*
         * Callback to run after modifying elements within the template
         *
         * @prop t - document fragment from the template element with values from elements inserted
         */
        callback = function(t){ }

    return { elements: elements, callback: callback };
 
    } else { return { elements: [], callback: function(){ return false; } } }
}
